<template>
  <base-modal dom-id="flexibleOptionsModal" :show="show" bodyClass="modal-body white-theme" sizeClass="modal-md" headerClass="modal-header-booking" class="calendar-modal" :hide-footer="true">
    <div slot="content" class="px-2">
      <h4 class="font-16 gray-text text-center">Select when you would like to be scheduled and your reservation will be confirmed with our soonest availability</h4>
      <hr>
      <div class="form-group">
        <div class="form-row align-items-center my-2">
          <div class="col-auto my-1">
            <label class="form-item dropdown-label mt-1" for="">Preferred period:</label>
          </div>
          <div class="col-auto my-1">
            <date-range-picker v-model="preferredPeriod" :disabled-date="disabledBeforeTomorrowAndAfter3Months"></date-range-picker>
          </div>
        </div>
      </div>
      <div class="form-row align-items-center my-2">
        <div class="col-12 my-1">
          <label class="form-item dropdown-label">Preferred time:</label>
        </div>
        <div class="col-auto my-1">
          <label class="checkbox-set pr-3" v-for="option in options">{{option.text}}
            <input type="radio" name="contact" :value="option.value" v-model="preferredTime" />
            <span class="checkmark checkmark-blue"></span>
          </label>
        </div>
      </div>
      <p class="text-center">
        <a href="#" class="blue-text" data-toggle="collapse" data-target="#demo">More options...</a>
      </p>
      <div id="demo" class="collapse">
        <div class="form-group">
          <div class="form-row align-items-center my-2">
            <div class="col-12 my-1">
              <label class="form-item dropdown-label">Preferred Days:</label>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-6 col-6 my-1" v-for="(day, dayIdx) in days">
                <label class="checkbox-set pr-3">{{day}}
                  <input type="checkbox" name="days" v-model="preferredDays" :value="dayIdx" />
                  <span class="checkmark checkmark-blue"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row align-items-center my-2">
          <div class="col-auto my-1">
            <label class="checkbox-set">Book instantly
              <input type="radio" name="booking-req" v-model="confirm" :value="false" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
          <div class="col-auto my-1">
            <label class="checkbox-set">Ask to confirm first
              <input type="radio" name="booking-req" v-model="confirm" :value="true" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
        </div>
        <div class="form-row align-items-center my-2">
          <div class="col-12 my-1">
            <label class="form-item dropdown-label">Contact me via:</label>
          </div>
          <div class="col-auto my-1" v-for="contact in contacts">
            <label class="checkbox-set pr-3">{{contact}}
              <input type="checkbox" name="contact" v-model="preferredContacts" :value="contactValue(contact)" :disabled="contact == 'E-mail'" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
        </div>
      </div>
      <hr>
      <div class="form-row align-items-center">
        <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-primary main-btn" @click="$emit('selected', flexibleOptions())">Confirm</button>
      </div>
    </div>
  </base-modal>
</template>
<script>
import Vue from 'vue'

import BaseModal from '../modals/base_modal.vue'
import Spinner from '../shared/spinner.vue'
import DateRangePicker from '@/shared/date_range_picker.vue'

export default {
  props: ['show'],
  components: {
    BaseModal,
    DateRangePicker,
    Spinner
  },
  data() {
    return {
      preferredPeriod: null,
      preferredTime: [],
      options: [
        { value: null, text: 'Anytime' },
        { value: 'morning', text: 'Morning' },
        { value: 'afternoon', text: 'Afternoon' },
      ],
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      preferredDays: [],
      contacts: ['E-mail', 'Phone', 'SMS'],
      preferredContacts: ['email'],
      confirm: false,
      loading: false,
    }
  },
  methods: {
    contactValue(contact) {
      return contact.toLowerCase().replace('-', '')
    },
    disabledBeforeTomorrowAndAfter3Months(date) {
      return date < Date.parse("tomorrow") || date > Date.parse("+ 3months")
    },
    flexibleOptions() {
      var params = {
        preferredPeriod: this.preferredPeriod,
        preferredContacts: this.preferredContacts, confirm: this.confirm
      }

      if (this.preferredPeriod) {
        params.description = 'Flexible within between '
        params.description += this.preferredPeriod[0].toString("M/d/yyyy") + ' and ' + this.preferredPeriod[1].toString("M/d/yyyy")
      }

      if (this.preferredTime) {
        params.preferredTime = this.preferredTime
        params.description += ', ' + this.preferredTime
      }
      if (this.preferredDays.length > 0) {
        params.preferredDays = this.preferredDays.sort()
        params.description += ' on ' + params.preferredDays.map((i) => {
          return this.days[i]
        }).join(' or ')
      }
      return params
    }
  }
}
</script>
